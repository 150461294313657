<template>
  <div class="myIntroduce">
    <div class="page_title">
      我的简历
    </div>
    <div class="page_content">
      <div class="page_area_title">
        请选择身份类型
      </div>
      <div class="identity_type">
        <div :class="['identity_type_item',submitObj.type==item.value?'active_type_item':'']" v-for="item in identity_type" :key="item.name"
          @click="changeIdentity(item.value)">
          {{  item.name}}
        </div>
      </div>
      <div class="form_box">
        <el-form :model="submitObj" :rules="curRuels" ref="submitObj" label-width="100px" class="demo-submitObj">
          <el-form-item label="姓名:" prop="name">
            <el-input v-model="submitObj.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="当前学历:" prop="education">
            <el-select v-model="submitObj.education" placeholder="请选择个人最高学历">
              <el-option :label="item.name" :value="item.name" v-for="item in educationList" :key="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工龄（年）:" prop="work_age">
            <el-input v-model="submitObj.work_age" placeholder="请输入工龄"></el-input>
          </el-form-item>
          <div class="page_area_title">
            求职信息
          </div>
          <el-form-item :label="curFormItemName[submitObj.type]['person_num']" prop="person_num" v-if="submitObj.type!==1">
            <el-input v-model="submitObj.person_num" placeholder="请输入具体人数"></el-input>
          </el-form-item>
          <el-form-item :label="curFormItemName[submitObj.type]['build_type_ids']" prop="build_type_ids" v-if="submitObj.type!==1">
            <el-cascader v-model="submitObj.build_type_ids" collapse-tags :props="props" :options="$store.state.categoryConfig.build_type"
              placeholder="请选择施工类型"></el-cascader>
          </el-form-item>
          <el-form-item :label="curFormItemName[submitObj.type]['category_ids']" prop="category_ids">
            <el-cascader v-model="submitObj.category_ids" collapse-tags :props="props" :options="$store.state.categoryConfig.job"
              placeholder="请选择工种"></el-cascader>
          </el-form-item>
          <el-form-item label="期望地区:" prop="area_code">
            <RegionSelect :value="submitObj.area_code" @changeRegion="changeRegion"></RegionSelect>
          </el-form-item>
          <el-form-item label="找活状态:" prop="find_status">
            <el-radio-group v-model="submitObj.find_status">
              <el-radio :label="1">找活中</el-radio>
              <el-radio :label="2">干活中</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="page_area_title">
            <span class="must_point">*</span> 自我介绍
          </div>
          <div class="text_area_box">
            <el-form-item prop="desc">
              <el-input type="textarea" v-model="submitObj.desc" placeholder="可简单介绍一下自己"></el-input>
            </el-form-item>
          </div>
        </el-form>

      </div>
      <button class="blue_btn" @click="submitForm('submitObj')">保存简历</button>
    </div>
  </div>
</template>
  
<script>
import RegionSelect from '../../../components/regionSelect.vue'
export default {
  components: {
    RegionSelect
  },
  data () {
    return {
      props: { multiple: true, value: 'id', label: 'name', children: 'child' },
      identity_type: [
        { name: '个人', value: 1 },
        { name: '班组', value: 2 },
        { name: '施工队', value: 3 },
      ],
      curFormItemName: {
        1: { category_ids: '求职意向:' },
        2: { category_ids: '包含工种:', build_type_ids: '班组类型:', person_num: '班组人数:' },
        3: { category_ids: '包含工种:', build_type_ids: '施工类型:', person_num: '队伍人数:', },
      },
      educationList: [
        { name: '小学' },
        { name: '初中' },
        { name: '中专' },
        { name: '高中' },
        { name: '大专' },
        { name: '本科' },
        { name: '硕士研究生' },
        { name: '博士研究生' },
      ],
      submitObj: {
        type: 1,
        name: '',
        education: '',
        work_age: '',
        category_ids: [],
        person_num: '',
        build_type_ids: [],
        area: '',
        area_code: [],
        desc: '',
        find_status: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        education: [
          { required: true, message: '请选择学历', trigger: 'change' }
        ],
        work_age: [
          { required: true, message: '请输入工龄', trigger: 'blur' }
        ],
        category_ids: [
          { required: true, message: '请选择工种', trigger: 'change' }
        ],
        person_num: [
          { required: true, message: '请输入人数', trigger: 'blur' }
        ],
        build_type_ids: [
          { required: true, message: '请选择施工类型', trigger: 'change' }
        ],
        area_code: [
          { required: true, message: '请选择地区', trigger: 'change' }
        ],
        find_status: [
          { required: true, message: '请选择选择找活状态', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写介绍', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    changeIdentity (val) {
      this.submitObj.type = val;
    },
    changeRegion (val) {
      this.submitObj.area_code = val.area_code;
      this.submitObj.area = val.area;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.submitObj);
          let params = { ...this.submitObj };
          params.area_code = `${this.submitObj.area_code[0]}/${this.submitObj.area_code[1]}`;
          if (this.submitObj.type == 1) {
            params.category_ids = params.category_ids.map(item => {
              return item[1];
            }) + '';
            params.person_num = 1;
            delete params.build_type_ids;
          }

          if (this.submitObj.type == 3 || this.submitObj.type == 2) {
            params.category_ids = params.category_ids.map(item => {
              return item[1];
            }) + '';
            params.build_type_ids = params.build_type_ids.map(item => {
              return item[1];
            }) + '';
          }
          this.$api.editResume(params).then(res => {
            if (res.code == 1) {
              this.$message.success(res.msg);
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  },
  mounted () {
    // console.log(this.curFormItemName[])
    this.$api.getMyResume().then(res => {
      console.log(res, 'sagaga',);
      if (res.code == 1) {
        this.submitObj = {
          type: res.data.type,
          name: res.data.name,
          education: res.data.education,
          work_age: res.data.work_age,
          category_ids: [],
          person_num: res.data.person_num,
          build_type_ids: [],
          area: res.data.area,
          area_code: res.data.area_code.split('/'),
          desc: res.data.desc,
          find_status: res.data.find_status,
        }
        if (res.data.build_type_id_arr) {
          this.submitObj.build_type_ids = res.data.build_type_id_arr;
        }
        if (res.data.category_id_arr) {
          this.submitObj.category_ids = res.data.category_id_arr;
        }
      }
    })
  },
  watch: {

  },
  computed: {
    curRuels () {
      let params = JSON.parse(JSON.stringify(this.rules));
      switch (this.submitObj.type) {
        case 1:
          delete params.person_num;
          delete params.build_type_ids;
          return params;
        case 2:
          delete params.category_ids;
          return params;
        case 3:
          return params;
        default:
          delete params.person_num;
          delete params.build_type_ids;
          return params;
      }
    }
  }
}
</script>
  
<style lang='scss' scoped>
.myIntroduce {
  background-color: #fff;
  border-radius: 8px 8px 8px 8px;
  padding: 26px;
  .page_title {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
  }
  .page_content {
    padding-top: 24px;
    padding-left: 10px;
    .page_area_title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 13px;
    }
    .identity_type {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .identity_type_item {
        width: 76px;
        height: 31px;
        background: #f6f6f6;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        border: 1px solid #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        box-sizing: border-box;
        margin-right: 7px;
        cursor: pointer;
      }
      .active_type_item {
        background: #f3f5ff;
        opacity: 1;
        border: 1px solid #3f63ff;
        color: #3f63ff;
      }
    }
    .form_box {
      margin-bottom: 23px;
      /deep/ .el-select {
        width: 100%;
      }
      .text_area_box {
        /deep/ .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
        /deep/ .el-textarea textarea {
          height: 106px;
        }
      }
    }
  }
}
</style>